@import '~@ag-grid-community/core/dist/styles/ag-grid.scss';
@import '~@ag-grid-community/core/dist/styles/ag-theme-balham/sass/legacy/ag-theme-balham-v22-compat.scss';
.ag-theme-balham {
  .gray-text {
    color: #ada9a9;
  }

  .ag-icon-checkbox-unchecked,
  .ag-icon-checkbox-checked {
    font-size: 14px;
  }
}
