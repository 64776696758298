@import './static/style/1-tools/typography.less';
@import './static/style/2-pages/page.css';
@import './static/style/2-pages/common.css';
@import './static/style/2-pages/verification.less';
@import './static/style/1-tools/table.less';
@import './static/style/profile-menu';
@import './static/style/card';
@import './static/style/table';
@import './static/style/2-pages/auth.less';
@import './static/style/1-tools/form-components.less';
@import './static/style/2-pages/error-page.less';
@import './static/style/2-pages/identification.less';
@import './static/style/2-pages/sidebar.less';
@import './static/style/drawer.less';
@import './static/style/settings.less';
//commonly used styles

@primary-color: #1890ff;
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.align-horizonatlly-column {
  display: flex;
  align-items: center;
  justify-content: center !important;
  flex-direction: column;
}

.align-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-vertically {
  display: flex !important;
  align-items: center !important;
}

.primary-color {
  color: @primary-color;
}
.ant-select-dropdown-menu-item-selected {
  background: #b7e0f3 !important;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.7, 0.7, 0.7);
  }

  // 20% {
  //   -webkit-transform: scale3d(1.1, 1.1, 1.1);
  //   transform: scale3d(1.1, 1.1, 1.1);
  // }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  // 60% {
  //   opacity: 1;
  //   -webkit-transform: scale3d(1.03, 1.03, 1.03);
  //   transform: scale3d(1.03, 1.03, 1.03);
  // }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.format-gallery {
  img {
    border: 1px solid;
  }
  img.current {
    border: 2px double @primary-color;
  }
}

//Customizing behavior of 0 width sider trigger to appear lower than it appears now
span.ant-layout-sider-zero-width-trigger {
  top: 70%;
  right: -25px;
  width: 25px;
  height: 36px;
}
.ant-layout-sider-zero-width .sider-live-chat {
  display: none;
}

@form-item-margin-bottom: 5px;@form-vertical-label-padding: 0px;@breadcrumb-separator-color: #ffffff;@breadcrumb-link-color: #ffffff;