.tab-render-fail {
  margin: 15vh;
  font-size: 20px;
  font-weight: 600;
}

.zoom-btn {
  margin-left: 10vh;
  margin-right: 2px;
}

.page-desc {
  margin: 0 10px;
}

.tab-panel {
  margin-top: -60px;
}

.reinfer-disable {
  cursor: not-allowed;
  pointer-events: none;
}

.update-inference {
  font-size: 12px;
  color: rgb(24, 144, 255);
  padding-left: 10px;
  padding-top: 2px;
}

.ant-col.verification-sidebar {
  border-right: 1px solid #1b1922;
  display: flex;
  flex-direction: column;

  .ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-top-content.ant-tabs-card-content {
    overflow: auto;
  }
}

.field-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  color: #00000099;
  transition: color 0.1s ease-in-out;
  cursor: pointer;
}

.field-wrapper > .field {
  width: 100%;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  padding: 5px 10px;

  .overlay {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in;
  }
}

.field:hover .overlay {
  background-color: black;
  color: #fff;
  opacity: 0.5;
}

.field-wrapper > .field.source-active {
  background-color: #0c0d0e;
  color: #cecbcb;
}

.field-active {
  border: 1px solid #2f72ff;
}

// .read-only-field {
//   background: #2f72ff;
//   color: #fff;
// }

.field.source-active > textarea {
  border: 1px solid #2f72ff;
}
.field.source-active > button {
  background: #2f72ff;
  color: #fff;
}
.field-wrapper .field-label {
  // padding-left: 10px;
  width: 25%;
}

.field-active .ant-select {
  width: 100%;
}
.field-wrapper > .field.active {
  font-size: 1rem;
  background-color: #2b2e31;
  opacity: 0.9;
  color: #cecbcb;
  // animation: bounceIn 0.25s;

  .field-input {
    border: none;
    // background-color: initial;
    // text-align: right;
    box-shadow: none;
    width: 100%;

    .ant-select-selection-selected-value {
      width: 100%;
    }
  }

  input {
    border: none;
    // background-color: initial;
    // text-align: right;
    flex: 1 1;
    box-shadow: none;
  }

  input.ant-input-number-input {
    padding-right: 30px;
  }
  // input:not(.ant-select-search__field) {
  //   color: #fff;
  // }
}

.field-column {
  width: 33%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.action-menu {
  justify-content: center;
  display: flex;
  padding: 5px;
  position: absolute;
  bottom: 0;
  width: 30%;
  border-top: #bfbaba;
}

.other-form-fields {
  // margin-left: 30px;
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ag-react-container .ant-input-number-sm {
  width: 88px;
}

// .job-details-drawer {
//   position: absolute;
//   top: 250px;
//   left: 400px;
//   z-index: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 48px;
//   height: 48px;
//   font-size: 16px;
//   text-align: center;
//   background: #1890ff;
//   border-radius: 0 4px 4px 0;
//   cursor: pointer;
//   pointer-events: auto;
// }

.document-alerts {
  display: flex;
  // padding: 10px;
}

.pspdf-container iframe {
  pointer-events: all;
}
.pspdf-container.resizing iframe {
  pointer-events: none;
}
.resize-handle {
  z-index: 200;
  left: calc(50% - 10px);
  background-color: red;
  height: 10px;
  width: 20px;
  position: absolute;
  cursor: row-resize;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background-image: linear-gradient(90deg, #48c6ef, #6f86d6);
  svg {
    fill: rgba(255, 255, 255, 0.15);
    position: absolute;
    top: -7px;
    width: 20px;
  }
}
